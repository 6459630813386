import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        date
        title
        featured
        category
      }
      excerpt(truncate: true)
      html
    }
  }
`

const BlogPost = props => {
  return (
    <Layout>
      <div className="container pt-6">
        <SEO title={props.data.markdownRemark.frontmatter.title} description={props.data.markdownRemark.excerpt} article={true}></SEO>
        <article>
          <h1>{props.data.markdownRemark.frontmatter.title}</h1>
          <p className="fs-sm text-muted">{props.data.markdownRemark.frontmatter.date}</p>
          <div className="pt-2"
            dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}
          ></div>
        </article>
      </div>
    </Layout>
  )
}

export default BlogPost
